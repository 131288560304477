@import '~stream-chat-react/dist/css/v2/index.css';

.str-chat {
    --str-chat__border-radius-circle: 6px;
}

.str-chat__theme-light {
    --str-chat__primary-color: #009688;
    --str-chat__active-primary-color: #004d40;
    --str-chat__surface-color: #f5f5f5;
    --str-chat__secondary-surface-color: #fafafa;
    --str-chat__primary-surface-color: #e0f2f1;
    --str-chat__primary-surface-color-low-emphasis: #edf7f7;
    --str-chat__avatar-background-color: #bf360c;

}

.str-chat__theme-dark {
    --str-chat__primary-color: #0c2144;
    --str-chat__active-primary-color: #0A1128;
    --str-chat__surface-color: #2E2E2E;
    --str-chat__secondary-surface-color: #0c2144;
    --str-chat__primary-surface-color: #0c2144;
    --str-chat__primary-surface-color-low-emphasis: #0c2144;
    --str-chat__background-color: #121212;
    --str-chat__text-color: #FFFFFF;
    --str-chat__text-low-emphasis-color: #FFFFFF;
}
  
.str-chat__channel-search {
    margin-top: 1rem;
    margin-bottom: 1rem; /* 🔥 Adds space before first chat */
    padding: 0 0.875rem;
}