.verification-container-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.verification-container {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
}

@media (max-width: 768px) {
    .verification-container-wrapper {
        align-items: center; /* Center the wrapper on mobile */
        justify-content: center;
    }
}

.verification-character {
    width: 40px;
    height: 50px;
    border: 1px solid #E2E2ED; /* border */
    border-radius: 8px;
    text-align: center;
    font-size: 1.2rem; /* Smaller text */
    font-weight: normal; /* Remove bold */
    background: #FFFFFF; /* White */
    color: #1D1D26; /* textPrimary */
    transition: border-color 0.2s ease-in-out;
}

.verification-character-inactive {
    background: #F0F1F4; /* background */
}

.verification-character-selected {
    border-color: #3877FD; /* primary */
    outline: none;
}

.verification-character-filled {
    font-weight: normal;
    color: #1D1D26; /* textPrimary */
}
